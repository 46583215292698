<template>
  <div>
    <click-to-edit
      v-if="scope.row.scale"
      v-model="scope.row.scale.bonus"
      placeholder=""
      :disabled="!isFieldAvailableForEdit(scope.row, 'bonus') || (scope.row.scale && scope.row.scale.auto_bonus) || scope.row.scale_type === 'text'"
      digits-only
      @input="saveElementPropChange(scope.row, 'scale', {bonus:$event})"
    ></click-to-edit>
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-manual-bonus-table-column',
  mixins: [ElementPropTableColumn],
  components: {ClickToEdit},

  props: {},
  data() {
    return {

    }
  },
  computed: {},
  methods: {
    saveElementScaleData(element) {
      this.$api.card.saveElementScaleData({
        element_id: element.id,
        data: element.scale,
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Элемент успешно сохранен'
          });
        })
        .finally(() => {
          this.$emit('elements-changed');
        })
    },
  }
}

</script>

<style>

</style>
